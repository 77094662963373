import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import cruiseData from "../../constants/ShipCompany"; // Importing the JSON file
import { FaShip } from "react-icons/fa"; // React Icon for the dropdown
import "./navbar.css";
import logo from "../../images/log-1.png";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Refs to handle click detection outside
  const navbarRef = useRef(null);
  const dropdownRef = useRef(null);
  const hamburgerRef = useRef(null);

  const toggleMobileMenu = () => setIsMobile((prev) => !prev);
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  const handleOutsideClick = (event) => {
    // If the click is outside the navbar or dropdown, close them
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsMobile(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <div className="nav-text">
        <p>Good to know: Our prices always include taxes and port charges!</p>
      </div>
      <nav className="navbar" ref={navbarRef}>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <ul className={`nav-links ${isMobile ? "active" : ""}`}>
          <li><Link to="/login">Login</Link></li>
          <li><Link to="/signup">Sign up</Link></li>
          <li
            className={`dropdown ${isDropdownOpen ? "open" : ""}`}
            ref={dropdownRef}
            onClick={(e) => {
              e.stopPropagation(); // Prevent this click from propagating to the window
              toggleDropdown();
            }}
          >
            <Link to="#" className="dropdown-link">
              Ships <FaShip />
            </Link>
            <ul className="dropdown-menu">
              {cruiseData.companies.map((company, index) => (
                <li key={index}>
                  <Link
                    to={`/company-ships#${encodeURIComponent(company.name)}`}
                  >
                    {company.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <a href="#Footer">Contact</a>
          </li>
        </ul>
        <div
          className={`hamburger ${isMobile ? "cancel" : ""}`}
          ref={hamburgerRef}
          onClick={(e) => {
            e.stopPropagation(); // Prevent this click from propagating to the window
            toggleMobileMenu();
          }}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
