import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowCircleRight } from "react-icons/fa";
import "./Recommendation.css";
import { onAuthStateChanged } from "firebase/auth"; 
import { auth } from "../../Firebaseconfig";

const RecommendationsPage = ({ ships }) => {
  const navigate = useNavigate();

  const handleInfoButtonClick = (ship) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard"); // Redirect authenticated users to the dashboard
      } else {
        navigate("/login"); // Redirect unauthenticated users to the login page
      }
    });
  };
  // Check if ships is an array and if it's not empty
  if (!Array.isArray(ships) || ships.length === 0) {
    return <div>Loading...</div>; // Show loading state if no ships data
  }

  return (
    <div className="recommendation-page">
      <h2>All Recommendations</h2>
      <div className="recommendation-grid">
        {ships.map((ship, index) => (
          <div
            key={index}
            className="recommendation-card"
            onClick={() => handleInfoButtonClick(ship)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={ship.image}
              alt={ship.shipName}
              className="recommendation-image"
            />
            <div className="recommendation-details">
              <h3>{ship.shipName}</h3>
              <p>{ship.heading}</p>
              <p>{ship.description}</p>
              <div className="recommendation-price-row">
                <p>
                  from <strong>{ship.price}</strong> per Person
                </p>
                <button
                  className="info-button"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the card click event
                    handleInfoButtonClick(ship);
                  }}
                >
                  <FaArrowCircleRight />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendationsPage;
