import React, { useState, useEffect, useCallback } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, set } from "firebase/database";
import { FaCreditCard, FaPaypal, FaBitcoin } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";
import "./PaymentPage.css";
import { auth } from "../../Firebaseconfig";

const PaymentPage = ({ selectedShip, totalPrice, onBack  }) => {
  const [timer, setTimer] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);
  const [showForm, setShowForm] = useState(false);

// eslint-disable-next-line react-hooks/exhaustive-deps
const handleProcessingTimeout = useCallback(() => {
    updateBookingHistory("processing");
    setPaymentStatus("Payment Pending. Please complete the payment.");
  }, []); // No dependencies, so it won't change

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }

    if (timer === 0 && activePaymentMethod) {
      handleProcessingTimeout();
    }

    return () => clearInterval(interval);
  }, [timer, activePaymentMethod, handleProcessingTimeout]); // ✅ No ESLint warnings
;




    useEffect(() => {
          const reminderTimeout = setTimeout(() => {
              onAuthStateChanged(auth, (user) => {
                  if (user) {
                      const db = getDatabase();
                      const notificationsRef = ref(db, `notifications/${user.uid}`);
                     push(notificationsRef, {
    message: `Reminder: Please complete your payment for ${selectedShip.shipName}.`,
    read: false,
}).then(() => console.log("Notification added:", {
    message: `Reminder: Please complete your payment for ${selectedShip.shipName}.`,
    read: false,
}));


                  }
              });
          }, 5 * 60 * 60 * 1000); 
  
          return () => clearTimeout(reminderTimeout); 
      }, [selectedShip]);

  // Default values if no `selectedShip`
  const totalAmount = totalPrice || "0.00";

 

  if (!selectedShip) {
    return <div>No cruise selected.</div>;
  }

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };


const updateBookingStatus = (status) => {
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }

    if (!selectedShip) {
      console.error("No ship selected.");
      return;
    }

    const db = getDatabase();
    const bookingRef = ref(db, `booking-history/${user.uid}/${selectedShip.id}`); // ✅ Unique ID per ship

    const bookingData = {
      shipName: selectedShip?.shipName || "Unknown Cruise",
      status,
      date: selectedShip?.date ? new Date(selectedShip.date).toISOString() : new Date().toISOString(),
      amount: selectedShip?.prices?.[0]?.amount || "N/A",
      totalPrice: totalPrice && totalPrice !== "sold out" ? totalPrice : selectedShip?.prices?.[0]?.amount || "N/A",
    };

    console.log("Saving booking data:", bookingData); // Debugging

    set(bookingRef, bookingData) // ✅ Using `set()` ensures only one entry per ship
      .then(() => console.log("Booking history updated."))
      .catch((error) => console.error("Error updating booking history:", error));
  });
};


const updateBookingHistory = (status) => {
  onAuthStateChanged(auth, (user) => {
    if (user && selectedShip) {
      const db = getDatabase();
      const bookingRef = ref(db, `booking-history/${user.uid}`);
      const newBooking = {
        shipName: selectedShip.shipName,
        status,
        date: selectedShip?.date || new Date().toISOString(),
        amount: selectedShip.totalPrice || "N/A",
        totalPrice: totalPrice || "N/A", 
      };
      set(bookingRef, newBooking)
        .then(() => console.log("Booking history updated."))
        .catch((error) => console.error("Error updating booking history:", error));
    }
  });
};
        // Add corresponding notificati


    
const handlePaymentMethod = (method) => {
  setActivePaymentMethod(method);
  setPaymentStatus("");
  setTimer(300); // Clear previous payment status

  let status = "";
  switch (method) {
    case "paypal":
      status = (
        <div className="payment-status">
          <h3>Payment Method: PayPal</h3>
          <p>
            To get our PayPal ID, please click the button below to chat with
            us on Telegram:
          </p>
          <button
            onClick={() => window.open("https://t.me/seacruisetravels", "_blank")}
          >
            Chat Us Up on Telegram
          </button>
        </div>
      );
      break;

    case "bank":
      status = (
        <div className="payment-status">
          <h3>Payment Method: Bank Transfer</h3>
          <p>Currently Not Available</p>
        </div>
      );
      break;

    case "crypto":
      status = (
        <div className="payment-status">
          <h3>Payment Method: Crypto</h3>
          <ul>
            <li>Bitcoin: bc1qxgnh737m2vxwz3m8ak4vgkkl7n3waz95zqt2lc</li>
            <li>Ethereum: 0xc887f852B5299A3021d6d0921884C93DFfb865aa</li>
            <li>Solana: 5QsgiZ1KkAoKT6VeMjj3B7bog1k6WdLUr8g43fokjsGF</li>
          </ul>
        </div>
      );
      break;

    case "card":
      status = (
        <div className="payment-status">
          <h3>Payment Method: Bank Card</h3>
          <p>Currently Not Available</p>
        </div>
      );
      break;

    default:
      status = (
        <div className="payment-status">
          <h3>Unknown Payment Method</h3>
          <p>Sorry, we don't support this method.</p>
        </div>
      );
  }
  setPaymentStatus(status);
};







  const handleFormClose = () => {
    setShowForm(false);
  };

  

  

  if (!totalAmount) return null;

  return (
    <div className="payment-page">
      <h1>Choose a Payment Method</h1>
      <h2>
        Total Amount: {selectedShip.currency || "$"} {totalAmount}
      </h2>

      <div className="payment-options">
        <div
          onClick={() => handlePaymentMethod("card")}
          className={`payment-option ${
            activePaymentMethod === "card" ? "active" : ""
          }`}
        >
          <FaCreditCard size={20} color="#007bff" />
          <p>Card</p>
        </div>
        <div
          onClick={() => handlePaymentMethod("paypal")}
          className={`payment-option ${
            activePaymentMethod === "paypal" ? "active" : ""
          }`}
        >
          <FaPaypal size={20} color="#003087" />
          <p>PayPal</p>
        </div>
        <div
          onClick={() => handlePaymentMethod("bank")}
          className={`payment-option ${
            activePaymentMethod === "bank" ? "active" : ""
          }`}
        >
          <MdAccountBalance size={20} color="#28a745" />
          <p>Bank Transfer</p>
        </div>
        <div
          onClick={() => handlePaymentMethod("crypto")}
          className={`payment-option ${
            activePaymentMethod === "crypto" ? "active" : ""
          }`}
        >
          <FaBitcoin size={20} color="#f7931a" />
          <p>Crypto</p>
        </div>
      </div>


      {paymentStatus && (
      <div className="payment-details">
    <p>{paymentStatus}</p>
    {timer > 0 && <p>Time Remaining: {formatTime(timer)}</p>}
    {timer > 0 && activePaymentMethod && (
     <button
  onClick={() => {
    updateBookingStatus("paid"); 
     updateBookingHistory("paid"); 
    setPaymentStatus("Payment Successful!"); 
    setShowForm(true); 
    console.log("Payment confirmed by user."); // Log the
  }}
  className="confirm-payment-button"
>
  I Have Made Payment
</button>

    )}
    {timer === 0 && (
    <button
        onClick={() => {
            setTimer(300); 
            setPaymentStatus(""); 
            handleProcessingTimeout();
        }}
        className="retry-payment-button"
    >
        Retry Payment
    </button>
)}

    
  </div>
      )}

      {/* Modal for Tally Form */}
      {showForm && (
        <div className="form-modal">
          <div className="form-container">
            <iframe
              src="https://tally.so/embed/n0bgpN?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
              loading="lazy"
              width="100%"
              height="400"
              style={{
                border: "none",
                margin: 0,
                padding: 0,
                backgroundColor: "whitesmoke",
                color: "black",
              }}
              title="💼 Receipt"
            />
            <button onClick={handleFormClose} className="close-button">
              Close
            </button>
          </div>
        </div>
      )}
      <button onClick={onBack} className="back-button">Back</button>
    </div>
  );
};

export default PaymentPage;
