import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set, update } from "firebase/database";
import { auth } from "../../Firebaseconfig";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import emailjs from "@emailjs/browser"; // Import EmailJS
import "./Signup.css";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
  });
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendWelcomeEmail = () => {
    emailjs
      .send(
        "service_twao09j", 
        "template_giddo75", 
        {
          user_name: `${formData.firstName} ${formData.lastName}`,
          user_email: formData.email,
        },
        "0iGHvBCjFC3cqnR7W" // Replace with your EmailJS public key
      )
      .then(() => console.log("Welcome email sent!"))
      .catch((err) => console.error("Error sending welcome email:", err));
  };

  const notifyAdmin = () => {
  const html_message = `
    <p style="font-size: 18px; font-weight: bold;">🚀 New Signup Alert!</p>
    <p>Hello Admin,</p>
    <p>A new user has just signed up on your website:</p>
    <ul>
      <li><strong>Name:</strong> ${formData.firstName} ${formData.lastName}</li>
      <li><strong>Email:</strong> ${formData.email}</li>
    </ul>
    <p style="padding: 12px; background-color: #f3f3f3; border-left: 4px solid #ffcc00;">
      <strong>“Stay alert! More users are coming in.” 🚀</strong>
    </p>
    <p>Make sure to follow up if necessary.</p>
    <p>Best regards,<br>The System Bot 🤖</p>
  `;

  emailjs
    .send(
      "service_twao09j", 
      "template_giddo75", // Use any template ID (won't affect since we're sending HTML manually)
      {
        admin_email: "seacruisetravels.info@gmail.com",
        message: html_message, // Pass the HTML content as message
      },
      "your_public_key" // Replace with your EmailJS public key
    )
    .then(() => console.log("Admin notified!"))
    .catch((err) => console.error("Error notifying admin:", err));
};

  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");

    if (!acceptTerms) {
      setError("You must accept the terms and privacy policy.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      const db = getDatabase();
      await set(ref(db, "users/" + user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        email: formData.email,
      });

      await update(ref(db, `users/${user.uid}`), {
        memberSince: new Date().toISOString(),
      });

      sendWelcomeEmail();
      notifyAdmin();

      navigate("/dashboard");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="signup-modal">
      <form className="signup-form" onSubmit={handleSignup}>
        <h2>Sign Up</h2>
        <div className="name-fields">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleInputChange}
            required
          />
        </div>
        <input
          type="tel"
          name="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={handleInputChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <div className="password-input-wrapper">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
          <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="terms-checkbox">
          <input
            type="checkbox"
            id="acceptTerms"
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
          />
          <label htmlFor="acceptTerms">
            I have read and accept the <a href="/terms">Terms and Privacy Policy</a>
          </label>
        </div>
        <button type="submit">Sign Up</button>
        {error && <p className="error-message">{error}</p>}
        <p>Already have an account? <a href="/login">Log In</a></p>
      </form>
    </div>
  );
};

export default Signup;
