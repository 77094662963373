import React, { useState } from "react";
import { FaCheck, FaTimes, FaArrowRight } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import "./CruiseDetails.css";



const CruiseDetails = ({ selectedShip, onBack, setCurrentTab }) => {
  const [showItinerary, setShowItinerary] = useState(false);
 


  if (!selectedShip) {
    return <div>No cruise selected.</div>;
  }

  const handleBookingClick = () => {
    // Switch to the booking tab
    setCurrentTab("booking");
   

  };
  return (
    <div>
      <div className="sticky-overview">
        <div className="sticky-text">
          <h2>{selectedShip.shipName}</h2>
          <p className="cruise-price-pick">
            From  {selectedShip.price}
 / Cabin
          </p>
        </div>
        <div className="sticky-layout">
          <p className="cruise-date-pick">From {selectedShip.date}</p>
          <div>
            <button className="booking-button" onClick={handleBookingClick}>
              To Booking
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="cruise-detailss">
       

        <h1>{selectedShip.shipName}</h1>
        <div className="cruise-immages">
          <img
            src={selectedShip.image}
            alt={selectedShip.shipName}
            className="cruise-main-image"
          />
          {selectedShip.others && selectedShip.others.length > 0 && (
            <div className="cruise-other-images">
              {selectedShip.others.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${selectedShip.shipName} view ${index + 1}`}
                  className="cruise-other-image"
                />
              ))}
            </div>
          )}
        </div>
        <p className="cruise-description">{selectedShip.description}</p>

        {/* View Itinerary Section */}
        <div className="itinerary-section">
          <button
            className="view-itinerary-button"
            onClick={() => setShowItinerary(!showItinerary)}
          >
            {showItinerary ? "Hide Itinerary" : "View Itinerary"}
          </button>
          {showItinerary && (
            <ul className="itinerary-list">
              {selectedShip.itinerary.map((item, index) => (
                <li key={index} className="itinerary-item">
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Price Overview Section */}
        <div className="price-overview">
          <h2>Price Overview</h2>
          <div className="price-table">
            {selectedShip.prices.map((price, index) => (
              <div key={index} className="price-row">
                <span className="room-type">{price.type}</span>
                <span className="price">
                  from {selectedShip.currency || "$"} {price.amount} per Person
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Included in Cruise Price Section */}
        <div className="cruise-inclusions">
          <h2>Included in Cruise Price</h2>
          <ul className="included-benefits">
            {selectedShip.included.map((item, index) => (
              <li key={index} className="benefit">
                <FaCheck className="benefit-icon" /> {item}
              </li>
            ))}
          </ul>
          <h3>Not Included</h3>
          <p className="not-included">
            <FaTimes className="cancel-icon" /> {selectedShip.notIncluded}
          </p>
        </div>
         <button onClick={onBack} className="back-button">Back</button>
      </div>
    </div>
  );
};

export default CruiseDetails;
