import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider, PrivateRoute } from "./components/AuthContext/Auth"; 
import HomePage from "./Pages/Homepage";
import CruiseDetails from "./components/CruiseDetails/CruiseDetails";
import CompanyShipList from "./components/CompanyShipPage/CompanyShipList";
import ShipDetails from "./components/ShipDetails/ShipDetails";
import BookingPage from "./components/Booking/Booking";
import PaymentPage from "./components/PaymentPage/PaymentPage";
import RecommendationsPage from "./components/Recommendation/Recommendation";
import LoginPage from "./components/Login/Login"; // New login page
import SignupPage from "./components/Signup/Signup"; // New signup page
import Dashboard from "./components/Dashboard/Dashboard"; // New dashboard
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import ships from "./constants/ships";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/company-ships" element={<CompanyShipList />} />
            <Route path="/cruise/:cruiseName" element={<CruiseDetails />} />
            <Route path="/ship/:shipName" element={<ShipDetails />} />
            <Route path="/:shipName-booking" element={<BookingPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/dashboard/profile" element={<ProfilePage />} />
            <Route
              path="/recommendations"
              element={<RecommendationsPage ships={ships.recommendations} />}
            />

            {/* Protected Route */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            {/* Fallback Route */}
            <Route path="*" element={<HomePage />} />
             <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
