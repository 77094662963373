import React, { useState, useEffect } from "react";
import { ref, onValue, update, get } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { updatePassword, onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../../Firebaseconfig";
import {
  FaUser,
  FaEnvelope,
  FaLock,
  FaSave,
  FaPhone,
  FaBirthdayCake,
  FaMapMarkerAlt,
  FaEye,
  FaEyeSlash,
  FaCalendarAlt,
  FaUserCircle,
  FaHome,
  FaFlag,
  FaClock,
  FaRegHeart,
} from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { ImCompass } from "react-icons/im";
import { IoSettings } from "react-icons/io5";
import { CiLogout } from "react-icons/ci";
import { IoMdArrowRoundBack } from "react-icons/io";
import "./ProfilePage.css";
import emailjs from "emailjs-com";


const ProfilePageWithSidebar = () => {
  const [activeTab, setActiveTab] = useState("My Profile");
    const [avatar, setAvatar] = useState(null);
    const [fullName, setFullName] = useState("");
    const [user, setUser] = useState(null);
     const [memberSince, setMemberSince] = useState("N/A");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [birthday, setBirthday] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [bookingHistory, setBookingHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sentReceipts, setSentReceipts] = useState({});
    const [loadingReceipts, setLoadingReceipts] = useState({});
    const navigate = useNavigate();
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [activeBookingTab, setActiveBookingTab] = useState("All Bookings");


useEffect(() => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const bookingRef = ref(db, `booking-history/${user.uid}`);
      onValue(bookingRef, (snapshot) => {
        const data = snapshot.val() || {};
        const allBookings = data ? Object.values(data) : [];
        setBookingHistory(allBookings);

        // Filter for Paid bookings
        const paidBookings = allBookings.filter((booking) => booking.status === "Paid");
        setFilteredHistory(paidBookings);
      });
    }
  });
}, []);

useEffect(() => {
  if (user?.uid) {
    const userRef = ref(db, `users/${user.uid}`);
    get(userRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const rawDate = snapshot.val().memberSince || "N/A";
          const formattedDate = rawDate !== "N/A" ? new Date(rawDate).toLocaleDateString() : "N/A";
          setMemberSince(formattedDate);
        } else {
          setMemberSince("N/A");
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setMemberSince("N/A");
      });
  }
}, [user?.uid]);



  // Fetch user data and booking history
  useEffect(() => {
    const fetchUserData = () => {
          onAuthStateChanged(auth, (user) => {
      if (user) { 
        setUser(user);
              const userRef = ref(db, `users/${user.uid}`);
              const bookingsRef = ref(db, `booking-history/${user.uid}`);
    
              // Fetch user profile data
              onValue(userRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                  setFirstName(data.firstName || "");
                  setLastName(data.lastName || "");
                  setFullName(`${data.firstName || ""} ${data.lastName || ""}`.trim());
                  setEmail(user.email || "");
                  setPhone(data.phone || "");
                  setBirthday(data.birthday || "");
                  setAddress(data.address || "");
                  setCity(data.city || "");
                  setState(data.state || "");
                  setCountry(data.country || "");
                  setMemberSince(data.memberSince || "N/A");
                  setAvatar(data.avatar || null);
                }
              });
    
              // Fetch booking history data
onValue(bookingsRef, (snapshot) => {
  const data = snapshot.val();
  if (data) {
    const newBookings = Object.values(data).map((booking) => ({
      cruiseName: booking.shipName || "Unknown Cruise",
      date: booking.date ? new Date(booking.date).toLocaleDateString() : "Date Not Available",
      status: booking.status || "Unknown Status",
      amount: booking.amount || "N/A",
      totalPrice: booking.totalPrice && booking.totalPrice !== "sold out" ? booking.totalPrice : booking.amount || "N/A",
    }));

    console.log("Fetched booking history:", newBookings); // Debugging

    setBookingHistory(newBookings); // ✅ Replace, do not append
  } else {
    setBookingHistory([]); // No bookings
  }
  setIsLoading(false);
});

        }
      });
    };
    fetchUserData();
  }, []);
  const handleAvatarUpload = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = async () => {
      const base64Image = reader.result; // Base64 string of the image
      setAvatar(base64Image); // Update local state

      onAuthStateChanged(auth, (user) => {
        if (user) {
          const userRef = ref(db, "users/" + user.uid);
          update(userRef, { avatar: base64Image })
            .then(() => alert("Avatar updated successfully."))
            .catch((error) => console.error("Error updating avatar: ", error));
        }
      });
    };
    reader.readAsDataURL(file);
  }
};


  const handleProfileUpdate = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userRef = ref(db, "users/" + user.uid);
        update(userRef, { firstName, lastName, phone, birthday, address, city, state, country })
          .then(() => alert("Profile updated successfully."))
          .catch((error) => console.error("Error updating profile: ", error));
      }
    });
  };

  const handleChangePassword = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        updatePassword(user, newPassword)
          .then(() => alert("Password updated successfully."))
          .catch((error) => console.error("Error updating password: ", error));
      }
    });
  };
  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/login");
    });
  };

  

const filterBookings = (status) => {
  if (status === "All Bookings") return bookingHistory;

  return bookingHistory.filter((booking) => {
    const bookingStatus = booking.status?.toLowerCase();

    if (status.toLowerCase() === "processing") {
      return bookingStatus === "processing" || bookingStatus === "pending"; // Match both processing and pending
    }
    if (status.toLowerCase() === "completed") {
      return bookingStatus === "completed"; // Include completed bookings
    }
    if (status.toLowerCase() === "paid") {
      return bookingStatus === "paid"; // Include paid bookings
    }

    return false; // If no match, return nothing
  });
};



const sendEmailReceipt = (booking) => {
  const emailContent = `
    <div style="font-family: Arial, sans-serif; color: #333;">
      <h1 style="text-align: center; color: #007bff;">Cruise Receipt</h1>
      <p>Dear ${firstName},</p>
      <p>Thank you for your payment for the cruise <strong>${booking.cruiseName}</strong>.</p>
      <p><strong>Booking Details:</strong></p>
      <ul>
        <li><strong>Cruise:</strong> ${booking.cruiseName}</li>
        <li><strong>Date:</strong> ${booking.date}</li>
        <li><strong>Status:</strong> ${booking.status}</li>
        <li><strong>Amount Paid:</strong> ${booking.amount || "N/A"}</li>
      </ul>
      <p>We look forward to seeing you on board!</p>
      <p style="text-align: center;">Safe Travels,<br>The Cruise Team</p>
    </div>
  `;

  const templateParams = {
    to_name: fullName,
    user_email: email,
    subject: "Cruise Payment Receipt",
    html_content: emailContent,
  };

  emailjs
    .send(
      "service_yourServiceId", // Replace with your EmailJS service ID
      "template_yourTemplateId", // Replace with your EmailJS template ID
      templateParams,
      "yourPublicKey" // Replace with your EmailJS public key
    )
    .then(
      (response) => {
        console.log("Email sent successfully:", response.text);
      },
      (error) => {
        console.error("Error sending email:", error);
      }
    );
};

const handleSendReceipt = async (booking) => {
  setLoadingReceipts((prev) => ({ ...prev, [booking.cruiseName]: true }));
  try {
    await sendEmailReceipt(booking);
    setSentReceipts((prev) => ({ ...prev, [booking.cruiseName]: true }));
    alert("Receipt sent successfully!");
  } catch (error) {
    console.error("Error sending receipt:", error);
    alert("Failed to send receipt. Please try again later.");
  } finally {
    setLoadingReceipts((prev) => ({ ...prev, [booking.cruiseName]: false }));
  }
};


useEffect(() => {
  const filtered = filterBookings(activeBookingTab);
  setFilteredHistory(filtered);
}, [activeBookingTab, bookingHistory]);

const renderBookingHistory = () => (
  <div className="booking-history">
    <h3>Booking History</h3>
    <div className="tabs">
      <button onClick={() => setActiveBookingTab("All Bookings")}>All Bookings</button>
      <button onClick={() => setActiveBookingTab("Paid")}>Paid</button>
      <button onClick={() => setActiveBookingTab("Completed")}>Completed</button>
      <button onClick={() => setActiveBookingTab("Processing")}>Processing</button>
    </div>

    <ul>
      {filteredHistory.length > 0 ? (
        filteredHistory
          .filter((booking) => booking.date !== "Invalid Date") // ✅ Remove invalid dates
          .map((booking, index) => (
          <li key={index}>
            <strong>{booking.cruiseName || "Unknown Cruise"}</strong> -{" "}
            {booking.status || "Unknown Status"} -{" "}
            {booking.date !== "Invalid Date" ? new Date(booking.date).toLocaleDateString() : "Date Not Available"} -{" "}
            <strong>Total Price:</strong> {booking.totalPrice || "N/A"}
          </li>
        ))
      ) : (
        <p>No bookings available.</p>
      )}
    </ul>
  </div>
);



    const getBreadcrumb = () => {
        return (
          <div className="breadcrumb">
  <span
    className="breadcrumb-link"
    onClick={() => navigate("/dashboard")} // Navigate to the dashboard page
    style={{ cursor: "pointer" }}
  >
    <FaHome /> Home
  </span>{" "}
  {">"} {activeTab}
</div>

        );
      };

   const renderTabContent = () => {
      switch (activeTab) {
        case "My Profile":
          return (
            <div className="profile-section">
              <h3>Profile Information</h3>
              <form className="profile-form">
                <label>
                  <FaUser /> First Name:
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Enter First Name"
                    className="name-input"
                  />
                </label>
                <label>
                  <FaUser /> Last Name:
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Enter Last Name"
                    className="name-input"
                  />
                </label>
                <label>
                  <FaEnvelope /> Email (read-only):
                  <input type="email" value={email} readOnly />
                </label>
                <label>
                  <FaPhone /> Phone:
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter Phone Number"
                  />
                </label>
                <label>
                  <FaBirthdayCake /> Birthday:
                  <input
                    type="date"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                    placeholder="Enter Birthday"
                  />
                </label>
                <label>
                  <FaMapMarkerAlt /> Address:
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Enter Address"
                  />
                </label>
                <label>
                  <ImCompass /> City:
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Enter City"
                  />
                </label>
                <label>
                  <BiWorld /> State:
                  <input
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    placeholder="Enter State"
                  />
                </label>
                <label>
                  <FaFlag /> Country:
                  <input
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Enter Country"
                  />
                </label>
                <button type="button" onClick={handleProfileUpdate}>
                  <FaSave /> Save Profile
                </button>
              </form>
            </div>
          );
  
        case "Change Password":
          return (
            <div className="password-section">
              <h3>Change Password</h3>
              <form className="password-form">
                <label>
                  <FaLock /> New Password:
                  <div className="password-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Enter New Password"
                    />
                    <span onClick={() => setShowPassword(!showPassword)} className="toggle-password">
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </label>
                <button type="button" onClick={handleChangePassword}>
                  <FaSave /> Change Password
                </button>
              </form>
            </div>
          );

      case "Booking History":
        return renderBookingHistory();

          case "Wishlist":
        return (
          <div className="profile-content">
            <h2>Wishlist</h2>
            <p>Your wishlist will appear here.</p>
          </div>
        );


      default:
        return null;
    }
  };

  return (
    <div className="profile-container">
      {/* Sidebar Section */}
      <div className="profile-sidebar">
        <div className="profile-avatar">
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarUpload}
            hidden
            id="avatar-upload"
          />
          {avatar ? (
            <img
              src={avatar}
              alt="Profile Avatar"
              className="avatar-image"
              onClick={() => document.getElementById("avatar-upload").click()}
            />
          ) : (
            <FaUserCircle
              size={120}
              color="#4caf50"
              className="avatar-icon"
              onClick={() => document.getElementById("avatar-upload").click()}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>

        <h3 className="hide">{fullName || "User"}</h3>
        <p className="member-since, hide">
          <FaCalendarAlt className="member-icon"/>Member Since: {memberSince}
        </p>
        <ul className="profile-menu">
          <li className={activeTab === "My Profile" ? "active" : ""} onClick={() => setActiveTab("My Profile")}>
            <IoSettings style={{ color: "white",marginRight:"0.4rem" }} /><span className="hide-text">My Profile</span>
          </li>
          <li className={activeTab === "Booking History" ? "active" : ""} onClick={() => setActiveTab("Booking History")}>
           <FaClock style={{ color: "white",marginRight:"0.4rem" }}/> <span className="hide-text">Booking History</span>
          </li>
          <li className={activeTab === "Wishlist" ? "active" : ""} onClick={() => setActiveTab("Wishlist")}>
            <FaRegHeart style={{ color: "white",marginRight:"0.4rem" }} /><span className="hide-text">Wishlists</span>
          </li>
           <li className={activeTab === "Change Password" ? "active" : ""} onClick={() => setActiveTab("Change Password")}>
            <FaLock style={{ color: "white" ,marginRight:"0.4rem" }} /><span className="hide-text">Change Password</span>
          </li>
          <li onClick={handleLogout}><CiLogout style={{ color: "white",marginRight:"0.4rem" }} /><span className="hide-text">Logout</span></li>
          <li onClick={() => navigate("/dashboard")}>
    <IoMdArrowRoundBack style={{ color: "white", marginRight: "0.4rem" }} />
    <span className="hide-text">Back to Dashboard</span>
  </li>
        </ul>
      </div>

      {/* Main Content Section */}
       <div className="main-content">
        {getBreadcrumb()}
        <div className="profile-page">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default ProfilePageWithSidebar;
