// Importing images
import aidabellaImg from "../images/shipCard/aidabella-schiffsansicht.cvt5bau5.jpg";
import aidalunaImg from "../images/shipCard/fortuna.webp";
import aussenansicht1Img from "../images/shipCard/aussenansicht.4ytk9v6q.webp";
import aussenansicht2Img from "../images/shipCard/aussenansicht-7.webp";
import aussenansicht4Img from "../images/shipCard/aussenansicht.f34vjqo5.webp";
import schiffsansichtImg from "../images/shipCard/schiffsansicht.e3q7dwhk.webp";
import indi1 from "../images/shipCard/indi1.jpg";
import allure1 from "../images/shipCard/allur1.jpg";
import har1 from "../images/shipCard/har1.jpg";
import ruby1 from "../images/shipCard/ruby1.jpg";
import radiance1 from "../images/shipCard/Radiance1.jpg";
import nor1 from "../images/shipCard/nor1.webp";
import bliss1 from "../images/shipCard/bliss1.jpg";
import pic1 from "../images/favourite/pic-1.jpg";
import pic2 from "../images/favourite/pic-2.jpg";
import pic3 from "../images/favourite/pic-3.jpg";
import pic4 from "../images/favourite/pic-4.jpg";
import pic5 from "../images/favourite/pic-5.jpg";
import pic6 from "../images/favourite/pic-7.jpg";
import pic7 from "../images/favourite/pic-8.jpg";
import pic8 from "../images/favourite/pic-9.jpg";
import pic9 from "../images/favourite/pic-10.jpg";
import pic10 from "../images/favourite/pic-11.jpg";
import pic11 from "../images/favourite/pic-12.jpg";
import pic12 from "../images/favourite/pic-13.jpg";
import pic13 from "../images/favourite/pic-14.jpg";
import pic14 from "../images/favourite/pic-15.jpg";
import pic15 from "../images/favourite/pic-16.jpg";
import pic16 from "../images/favourite/pic-17.jpg";
import pic17 from "../images/favourite/pic-18.jpg";
import pic18 from "../images/favourite/pic-19.jpg";
import indi2 from "../images/favourite/indi2.jpg";
import indi3 from "../images/favourite/indi3.jpg";
import indi4 from "../images/favourite/indi4.jpg";
import allure2 from "../images/favourite/allur2.jpg";
import allure3 from "../images/favourite/allur3.jpg";
import allure4 from "../images/favourite/allur4.jpg";
import har2 from "../images/favourite/har2.jpg";
import har3 from "../images/favourite/har3.jpg";
import har4 from "../images/favourite/har4.jpg";
import nor2 from "../images/favourite/nor2.webp";
import nor3 from "../images/favourite/nor3.webp";
import nor4 from "../images/favourite/nor4.webp";
import radiance2 from "../images/favourite/Radiance2.jpg";
import radiance3 from "../images/favourite/Radiance3.jpg";
import radiance4 from "../images/favourite/Radiance4.jpg";
import bliss2 from "../images/favourite/bliss2.jpg";
import bliss3 from "../images/favourite/bliss3.jpg";
import bliss4 from "../images/favourite/bliss4.jpg";
import bliss5 from "../images/favourite/bliss5.jpg";
import ruby2 from "../images/favourite/ruby2.jpg";
import ruby3 from "../images/favourite/ruby3.jpg";
import ruby4 from "../images/favourite/ruby4.jpg";
import ruby5 from "../images/favourite/ruby5.jpg";
const ships = {
  recommendations: [
    {
      shipName: "AIDAstella",
      heading: "14 days Asia with AIDAstella ",
      description: "from/to Laem Chabang",
      image: aidabellaImg,
      price: "$1100",
      date: "22th December 2024",
      itinerary: [
        "Day 1: Depart from Hamburg at 5:00 PM",
        "Day 2: At Sea",
        "Day 3: At Sea",
        "Day 4: Las Palmas, Gran Canaria (8:00 AM - 5:00 PM)",
        "Day 5: Santa Cruz de Tenerife, Canary Islands (8:00 AM - 5:00 PM)",
        "Day 6: At Sea",
        "Day 7: Madeira, Portugal (8:00 AM - 6:00 PM)",
        "Day 8: At Sea",
        "Day 9: At Sea",
        "Day 10: Return to Hamburg, arriving at 7:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 299 },
        { type: "Outside", price: 499 },
        { type: "Balcony", price: 699 },
        { type: "Suite", price: 999 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: 1100 },
        { type: "Outside", amount: 1500 },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage trasport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [pic1, pic2, pic3],
    },
    {
      shipName: "Costa Fortuna",
      heading: "7 days Eastern Carribean with Costa Fotuna",
      description: "From/to Pointe-à-pitre",
      image: aidalunaImg,
      price: "$1050",
      date: "18th December 2024",
      itinerary: [
        "Day 1: Depart from Genoa at 6:00 PM",
        "Day 2: At Sea",
        "Day 3: Naples, Italy (7:00 AM - 6:00 PM)",
        "Day 4: Messina, Sicily (7:00 AM - 6:00 PM)",
        "Day 5: Civitavecchia, Italy (7:00 AM - 6:00 PM)",
        "Day 6: Savona, Italy (9:00 AM - 6:00 PM)",
        "Day 7: Return to Genoa, arriving at 9:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 299 },
        { type: "Outside", price: 499 },
        { type: "Balcony", price: 699 },
        { type: "Suite", price: 999 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: 1050 },
        { type: "Outside", amount: 1650 },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage trasport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [pic4, pic5, pic6],
    },
    {
      shipName: "Azamara Journey",
      heading: "16 days Panama Canal with Azamara Journey",
      description: "From Maimi(FL) TO Callao(Lima)",
      image: aussenansicht1Img,
      price: "$1500",
      date: "20th December 2024",
      itinerary: [
        "Day 1: Depart from Miami at 6:00 PM",
        "Day 2-3: At Sea",
        "Day 4: Puerto Limon, Costa Rica (8:00 AM - 5:00 PM)",
        "Day 5: Cartagena, Colombia (8:00 AM - 6:00 PM)",
        "Day 6-7: At Sea",
        "Day 8: Buenos Aires, Argentina (7:00 AM - 6:00 PM)",
        "Day 9: Montevideo, Uruguay (8:00 AM - 5:00 PM)",
        "Day 10-14: At Sea",
        "Day 15: Return to Miami, arriving at 7:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 299 },
        { type: "Outside", price: 499 },
        { type: "Balcony", price: 699 },
        { type: "Suite", price: 999 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: 1500 },
        { type: "Outside", amount: 1300 },
        { type: "Suites", amount: "sold out" },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage trasport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [pic7, pic8, pic9],
    },
    {
      shipName: "Brilliance of the Seas",
      heading: "14 days Transatlantic with Brilliance of the seas",
      description: "From Miami(FL) to Barcelona",
      image: aussenansicht2Img,
      price: "$900",
      date: "14th December 2024",
      itinerary: [
        "Day 1: Depart from Tampa at 4:00 PM",
        "Day 2: At Sea",
        "Day 3: Grand Cayman (8:00 AM - 5:00 PM)",
        "Day 4: Cozumel, Mexico (7:00 AM - 6:00 PM)",
        "Day 5: At Sea",
        "Day 6: Key West, Florida (7:00 AM - 5:00 PM)",
        "Day 7: At Sea",
        "Day 8: Return to Tampa, arriving at 7:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 299 },
        { type: "Outside", price: 499 },
        { type: "Balcony", price: 699 },
        { type: "Suite", price: 999 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: 900 },
        { type: "Outside", amount: 1300 },
        { type: "Balcony", amount: 1900 },
        { type: "Junior Suite W/ Balcony", amount: 2500 },
        { type: "Suite W/ Balcony", amount: 3500 },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage trasport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [pic16, pic17, pic18],
    },
    {
      shipName: "MSC Lirica",
      heading: "7days Western Mediterranean with MSC Lirica",
      description: "From/to Valencia",
      image: aussenansicht4Img,
      price: "$950",
      date: "16th December 2024",
      itinerary: [
        "Day 1: Depart from Genoa at 6:00 PM",
        "Day 2: Marseille, France (7:00 AM - 6:00 PM)",
        "Day 3: At Sea",
        "Day 4: Barcelona, Spain (7:00 AM - 6:00 PM)",
        "Day 5: At Sea",
        "Day 6: Malta (8:00 AM - 6:00 PM)",
        "Day 7: At Sea",
        "Day 8: Athens, Greece (8:00 AM - 6:00 PM)",
        "Day 9: Dubrovnik, Croatia (7:00 AM - 5:00 PM)",
        "Day 10: Return to Genoa, arriving at 7:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 299 },
        { type: "Outside", price: 499 },
        { type: "Balcony", price: 699 },
        { type: "Suite", price: 999 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: "sold out" },
        { type: "Outside", amount: 1350 },
        { type: "Balcony", amount: 1900 },
        { type: "Suite W/ Balcony", amount: 3050 },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage trasport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [pic10, pic11, pic12],
    },
    {
      shipName: "Celebrity Eclipse",
      heading: "12 days Transatlantic with celebrity Eclipse  ",
      description: "from Fort Lauderdale(port Evergrades, FL) to Southampton",
      image: schiffsansichtImg,
      price: "$630",
      date: "18th December 2024",
      itinerary: [
        "Day 1: Depart from Fort Lauderdale at 3:30 PM",
        "Day 2-3: At Sea",
        "Day 4: Tortola, British Virgin Islands (8:00 AM - 6:00 PM)",
        "Day 5: St. John's, Antigua and Barbuda (7:00 AM - 4:00 PM)",
        "Day 6: Bridgetown, Barbados (9:30 AM - 6:00 PM)",
        "Day 7: Roseau, Dominica (8:00 AM - 6:00 PM)",
        "Day 8: Basseterre, St. Kitts & Nevis (8:00 AM - 6:00 PM)",
        "Day 9-10: At Sea",
        "Day 11: Return to Fort Lauderdale, arriving at 7:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 299 },
        { type: "Outside", price: 499 },
        { type: "Balcony", price: 699 },
        { type: "Suite", price: 999 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: "sold out" },
        { type: "Outside", amount: 1200 },
        { type: "Balcony Comfort", amount: 1500 },
        { type: "Suite W/ Balcony", amount: 4000 },
        
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage trasport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [pic13, pic14, pic15],
    },
    {
      shipName: "Independence of the Seas",
      heading: "7 Night Eastern Caribbean Holiday ",
      description:
        "From Miami, Florida to the Bahamas, Puerto Rico, and the U.S. Virgin Islands",
      image: indi1,
      price: "$1200",
      date: "14th December 2024",
      itinerary: [
        "Day 1: Depart from Miami, Florida at 4:00 PM",
        "Day 2: Nassau, Bahamas (7:00 AM - 4:00 PM)",
        "Day 3: Cruising (Day at Sea)",
        "Day 4: San Juan, Puerto Rico (10:30 AM - 7:00 PM)",
        "Day 5: Charlotte Amalie, St. Thomas (8:00 AM - 6:00 PM)",
        "Days 6-7: Cruising (Days at Sea)",
        "Day 8: Return to Miami, Florida, arriving at 6:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 700 },
        { type: "Outside", price: 650 },
        { type: "Balcony", price: 600 },
        { type: "Suite", price: 999 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: 1200 },
        { type: "Outside", amount: 1500 },
        { type: "Balcony", amount: 1850 },
        { type: "Junior Suite W/ Balcony", amount: 2500 },
        { type: "Suite W/ Balcony", amount: 5250 },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage trasport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [indi2, indi3, indi4],
    },
    {
      shipName: "Allure of the Seas",
      heading: "7 Night Eastern Caribbean Holiday ",
      description:
        "From Fort Lauderdale, Florida, sail to the sun-soaked beaches of St. Thomas",
      image: allure1,
      price: "$1050",
      date: "28th December 2024",
      itinerary: [
        "Day 1: Depart from Fort Lauderdale, Florida at 5:30 PM",
        "Days 2-3: Cruising (Days at Sea)",
        "Day 4: Charlotte Amalie, St. Thomas (8:00 AM - 6:00 PM)",
        "Day 5: Philipsburg, St. Maarten (8:00 AM - 6:00 PM)",
        "Day 6: San Juan, Puerto Rico (7:00 AM - 3:00 PM)",
        "Day 7: Cruising (Day at Sea)",
        "Day 8: Perfect Day at CocoCay, Bahamas (8:00 AM - 5:00 PM)",
        "Day 9: Return to Fort Lauderdale, Florida, arriving at 6:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 700 },
        { type: "Outside", price: 650 },
        { type: "Balcony", price: 600 },
        { type: "Suite", price: 999 },
      ],
     transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: 1050 },
        { type: "Outside", amount: 2500 },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage trasport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [allure2, allure3, allure4],
    },
    {
      shipName: "Harmony of the Seas",
      heading: "8-Night Western Caribbean Cruise",
      description:
        "Sail from Galveston to Perfect Day at CocoCay, Costa Maya, and Cozumel, Mexico.",
      image: har1,
      price: "$900",
      date: "14th December 2024",
      itinerary: [
        "Day 1: Depart from Galveston, Texas at 4:00 PM",
        "Days 2-3: Cruising (Days at Sea)",
        "Day 4: Perfect Day at CocoCay, Bahamas (7:00 AM - 5:00 PM)",
        "Day 5: Cruising (Day at Sea)",
        "Day 6: Puerto Costa Maya, Mexico (11:30 AM - 7:00 PM)",
        "Day 7: Cozumel, Mexico (7:00 AM - 5:00 PM)",
        "Day 8: Cruising (Day at Sea)",
        "Day 9: Return to Galveston, Texas, arriving at 7:00 AM",
      ],
      amenities: ["Free WiFi", "Spa", "Kids' Zone"],
      reviews: [
        { user: "John", comment: "Amazing experience!", rating: 5 },
        { user: "Sarah", comment: "Great service.", rating: 4 },
      ],
      cabins: [
        { type: "Inside", price: 700 },
        { type: "Outside", price: 650 },
        { type: "Balcony", price: 600 },
        { type: "Suite", price: 999 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: "sold out" },
        { type: "Outside", amount: 1500 },
        { type: "Balcony", amount: 1850 },
        { type: "Junior Suite W/ Balcony", amount: 2500 },
        { type: "Suite W/ Balcony", amount: 5250 },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage transport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [har2, har3, har4],
    },
    {
      shipName: "MS Bolette",
      currency: "£",
      heading: "8-Night Norwegian Fjords Cruise",
      description:
        "Embark from Liverpool to explore the Norwegian fjords, visiting Olden, Bergen, and Stavanger",
      image: nor1,
      price: "£650",
      date: "15th December 2024",
      itinerary: [
        "Day 1: Depart from Liverpool, UK at 5:00 PM",
        "Day 2-3: Cruising (Days at Sea)",
        "Day 4: Stavanger, Norway (10:00 AM - 6:00 PM)",
        "Day 5: Olden, Norway (7:00 AM - 5:00 PM)",
        "Day 6: Bergen, Norway (8:00 AM - 6:00 PM)",
        "Day 7: Cruising (Day at Sea)",
        "Day 8: Cruising (Day at Sea)",
        "Day 9: Return to Liverpool, UK, arriving at 7:00 AM",
      ],
      amenities: [
        "Free WiFi",
        "Spa",
        "Onboard Theatre",
        "Fitness Center",
        "Outdoor Pool",
      ],
      reviews: [
        {
          user: "Emily",
          comment: "Wonderful cruise through the fjords! Highly recommend.",
          rating: 5,
        },
        {
          user: "Michael",
          comment: "Great service and stunning views of the mountains.",
          rating: 4,
        },
      ],
      cabins: [
        { type: "Inside", price: "£620" },
        { type: "Outside", price: 620 },
        { type: "Balcony", price: 900 },
        { type: "Suite", price: 1300 },
      ],
      transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
      prices: [
        { type: "Inside", amount: "sold out" },
        { type: "Outside", amount: "sold out" },
        { type: "Balcony", amount: 1500 },
        { type: "Junior Suite W/ Balcony", amount: 2500 },
        { type: "Suite W/ Balcony", amount: 3250 },
      ],
      included: [
        "All meals on board",
        "All tips on board",
        "Entertainment program on board",
        "Baggage transport on embarkation and disembarkation",
        "Taxes and port charges",
        "Use of most onboard facilities",
      ],
      notIncluded:
        "Expenses for beverages, shore excursions, and personal spending.",
      others: [nor2, nor3, nor4],
    },
    {
  "shipName": "Radiance of the Seas",
  "heading": "5-Night Pacific Coastal Cruise",
  "description": "Sail from Los Angeles to Vancouver, with stops in San Francisco and Victoria, British Columbia.",
  "image": radiance1,
  "price": "$750",
  "date": "4th May 2025",
  "itinerary": [
    "Day 1: Depart from Los Angeles, California at 4:00 PM",
    "Day 2: San Francisco, California (8:00 AM - 5:00 PM)",
    "Day 3: Cruising (Day at Sea)",
    "Day 4: Victoria, British Columbia (9:00 AM - 6:00 PM)",
    "Day 5: Cruising (Day at Sea)",
    "Day 6: Arrive in Vancouver, British Columbia at 7:00 AM"
  ],
  "amenities": ["Free WiFi", "Spa", "Kids' Zone"],
  "reviews": [
    { "user": "Alice", "comment": "Beautiful ship and great service!", "rating": 5 },
    { "user": "Bob", "comment": "Enjoyed the destinations.", "rating": 4 }
  ],
  "cabins": [
    { "type": "Inside", "price": 700 },
    { "type": "Outside", "price": 850 },
    { "type": "Balcony", "price": 1000 },
    { "type": "Suite", "price": 1500 }
  ],
 transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
  "prices": [
    { "type": "Inside", "amount": 700 },
    { "type": "Outside", "amount": 850 },
    { "type": "Balcony", "amount": 1000 },
    { "type": "Suite", "amount": 1500 }
  ],
  "included": [
    "All meals on board",
    "Entertainment program on board",
    "Use of most onboard facilities",
    "Taxes and port charges"
  ],
  "notIncluded": "Expenses for beverages, shore excursions, and personal spending.",
  "others": [radiance2, radiance3, radiance4]
},
{
  "shipName": "Norwegian Bliss",
  "heading": "7-Day Alaska with Glacier Bay from Seattle",
  "description": "Experience the breathtaking beauty of Alaska, including Glacier Bay, with departures from Seattle.",
  "image": bliss1,
  "price": "$1,200",
  "date": "15th June 2025",
  "itinerary": [
    "Day 1: Depart from Seattle, Washington at 5:00 PM",
    "Day 2: At Sea",
    "Day 3: Juneau, Alaska (2:30 PM - 11:00 PM)",
    "Day 4: Skagway, Alaska (7:00 AM - 8:15 PM)",
    "Day 5: Glacier Bay (Cruising)",
    "Day 6: Ketchikan, Alaska (6:00 AM - 1:00 PM)",
    "Day 7: Victoria, British Columbia (7:30 PM - 11:59 PM)",
    "Day 8: Return to Seattle, Washington at 6:00 AM"
  ],
  "amenities": ["Spa", "Casino", "Broadway Shows"],
  "reviews": [
    { "user": "Charlie", "comment": "Stunning views and excellent amenities.", "rating": 5 },
    { "user": "Dana", "comment": "A trip to remember!", "rating": 5 }
  ],
  "cabins": [
    { "type": "Inside", "price": 1000 },
    { "type": "Oceanview", "price": 1200 },
    { "type": "Balcony", "price": 1500 },
    { "type": "Suite", "price": 2000 }
  ],
  transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
  "prices": [
    { "type": "Inside", "amount": 1000 },
    { "type": "Oceanview", "amount": 1200 },
    { "type": "Balcony", "amount": 1500 },
    { "type": "Suite", "amount": 2000 }
  ],
  "included": [
    "All meals on board",
    "Entertainment program on board",
    "Use of most onboard facilities",
    "Taxes and port charges"
  ],
  "notIncluded": "Expenses for beverages, shore excursions, and personal spending.",
  "others": [bliss2, bliss3, bliss4, bliss5]
},
{
  "shipName": "Ruby Princess",
  "heading": "7-Day Classic California Coast",
  "description": "Explore the iconic California coast with stops in San Francisco, Monterey, and San Diego.",
  "image": ruby1,
  "price": "$1,050",
  "date": "10th September 2025",
  "itinerary": [
    "Day 1: Depart from San Francisco, California at 4:00 PM",
    "Day 2: Monterey, California (7:00 AM - 5:00 PM)",
    "Day 3: At Sea",
    "Day 4: San Diego, California (8:00 AM - 5:00 PM)",
    "Day 5: Ensenada, Mexico (8:00 AM - 5:00 PM)",
    "Day 6: At Sea",
    "Day 7: Return to San Francisco, California at 7:00 AM"
  ],
  "amenities": ["Fine Dining", "Theater Shows", "Fitness Center"],
  "reviews": [
    { "user": "Eve", "comment": "A delightful journey along the coast.", "rating": 4 },
    { "user": "Frank", "comment": "Exceptional service and beautiful ports.", "rating": 5 }
  ],
  "cabins": [
    { "type": "Inside", "price": 900 },
    { "type": "Oceanview", "price": 1200 },
    { "type": "Balcony", "price": 1500 },
    { "type": "Suite", "price": 2000 }
  ],
  transportationOptions: [
        {
          type: "Railway",
          options: [
            { class: "Standard", price: 500 },
            { class: "First Class", price: 700 },
          ],
        },
        {
          type: "Air",
          options: [
            { class: "Economy", price: 500 },
            { class: "Business", price: 800 },
            { class: "First Class", price: 1300 },
          ],
        },
        {
          type: "Parking",
          options: [
            { class: "Basic", price: 200 },
            { class: "Premium", price: 500 },
          ],
        },
      ],
  "prices": [
    { "type": "Inside", "amount": 900 },
    { "type": "Oceanview", "amount": 1200 },
    { "type": "Balcony", "amount": 1500 },
    { "type": "Suite", "amount": 2000 }
  ],
  "included": [
    "All meals on board",
    "Entertainment program on board",
    "Taxes and port charges",
    "Use of most onboard facilities"
  ],
  "notIncluded": "Expenses for beverages, shore excursions, and personal spending.",
  "others": [ruby2, ruby3, ruby4, ruby5]
}
  ],
};

export default ships;
